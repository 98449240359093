import fetchData from "../services";
import { checkEmpPolicy } from "./documentAction";
import { openUploadFileModal } from "./myDocumentsAction";
import { BOTTOM_T_W_AL, POLICY_DOCUMENT_LIST, SEARCH_EMPLOYEE_POLICY, TABLE_DATA_FOR_POLICY } from "./types";
import * as actionTypes from "./types";
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

export const getPolicyDocuments = (skip, limit, activePage, search, userId) => {
    return async (dispatch) => {
        let formData = {};
        formData['skip'] = String(skip) ? +skip : '';
        formData['limit'] = limit ? +limit : '';
        formData['search'] = search ? search : '';
        formData['userId'] = userId ? userId : sessionStorage.getItem('userId');
        let result = await fetchData({ url: '/user/employee/policy/list', method: 'POST', data: formData });
        if (result.status === 1) {
            dispatch({
                type: POLICY_DOCUMENT_LIST,
                payload: result.response && result.response.length > 0 ? result.response : [],
                total: result.total ? result.total : 0
            });
            dispatch({
                type: TABLE_DATA_FOR_POLICY,
                payload: { skip, limit, activePage, search }
            });

        } else if (result.status === 2) {
            dispatch({
                type: POLICY_DOCUMENT_LIST,
                payload: [],
                total: 0
            });
        } else {
            dispatch({
                type: BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            });
        }
    }
}
export const searchPolicy = (val) => {
    return {
        type: SEARCH_EMPLOYEE_POLICY,
        payload: val ? val : ''
    }
}
export const clearSearchPolicy = () => {
    return {
        type: SEARCH_EMPLOYEE_POLICY,
        payload: ''
    }
}
export const addPolicyData = (data) => {
    return {
        type: actionTypes.POLICY_DOCUMENT_ADD_DATA,
        payload: data ? data : {}
    }
}
export const createpolicyDocument = (data, props) => {
    return async (dispatch) => {
        let formData = {};
        formData['name'] = data.name ? data.name : '';
        formData['policyNumber'] = data.policyNumber ? data.policyNumber : '';
        formData['status'] = data.status ? +data.status : '';
        formData['content'] = data.content ? (data.content) : '';
        dispatch({ type: actionTypes.BUTTON_PENDING })
        let result = await fetchData({ url: '/user/employee/policy/create', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_SUCCESS })
        if (result.status === 1) {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            });
            dispatch({
                type: actionTypes.EMPLOYEE_POLICY_CLEAR_DATA,
                payload: {}
            });
            props.history.push('/policies')
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            });
        }
    }
}
export const createMultipolicyDocument = (data, props) => {
    return async (dispatch) => {
        let formData = {};
        formData["policyDoc"] = data
        dispatch({ type: actionTypes.BUTTON_PENDING })
        let result = await fetchData({ url: '/user/employee/policy/multi/create', method: 'POST', data: formData });
        dispatch({ type: actionTypes.BUTTON_SUCCESS })
        if (result.status === 1) {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            });
            dispatch({
                type: actionTypes.EMPLOYEE_POLICY_CLEAR_DATA,
                payload: {}
            });
            props.history.push('/policies')
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            });
        }
    }
}
export const editPolicyData = (data) => {
    return {
        type: actionTypes.POLICY_DOCUMENT_EDIT_DATA,
        payload: data ? data : {}
    }
}
export const getPolicyById = (id) => {
    return async (dispatch) => {
        let result = await fetchData({ url: `/user/employee/policy/get?id=${id}`, method: 'GET' });
        if (result && result.status === 1) {
            dispatch({
                type: actionTypes.POLICY_DOCUMENT_EDIT_DATA,
                payload: result.response ? result.response : {}
            })
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            });
        }
    }
}
export const updatePolicyData = (data, props) => {
    return async (dispatch) => {
        let formData = {};
        formData['id'] = data.id ? data.id : '';
        formData['name'] = data.name ? data.name : '';
        formData['policyNumber'] = data.policyNumber ? data.policyNumber : '';
        formData['status'] = data.status ? +data.status : '';
        formData['content'] = data.content ? (data.content) : '';
        dispatch({ type: actionTypes.BUTTON_PENDING });
        let result = await fetchData({ url: `/user/employee/policy/update`, method: 'PATCH', data: formData });
        dispatch({ type: actionTypes.BUTTON_SUCCESS });
        if (result && result.status === 1) {
            dispatch({
                type: actionTypes.POLICY_DOCUMENT_EDIT_DATA,
                payload: {}
            })
            props.history.push('/policies');
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            });
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            });
        }
    }
}
export const deletePolicyDocument = (id) => {
    return async (dispatch) => {
        let result = await fetchData({ url: `/user/employee/policy/delete`, method: 'DELETE' ,data:{id:id}});
        if (result && result.status === 1) {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            });
            dispatch({
                type: actionTypes.DELETE_MODAL_VIEW,
                payload: {}
            });
            // dispatch({ type: actionTypes.UPDATED_POLICY_DOCUMENT_LIST, id: id });
            dispatch(getPolicyDocuments())
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            });
        }
    }
}
export const showBottomModal = (data) => {
    return {
        type: actionTypes.BOTTOM_T_W_AL,
        payload: { show_hide: true, text: data, alignment: "centre" }
    }
}
export const previewPolicy = (item) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.EMPLOYEE_POLICY_DOCUMENT_PREVIEW,
            payload: item ? item : ''
        });
    }
}
export const previewDocuments = (item,props) => {
    return (dispatch) => {
        props.history.push({
            pathname: '/documents',
            state: item.policyNumber
        })
    }
}
export const getViewData = (id, userId) => {
    return async (dispatch) => {
        let result = await fetchData({ url: `/user/employee/policy/view?id=${id}&userId=${userId}`, method: 'GET' });
        if (result && result.status === 1) {
            dispatch({
                type: actionTypes.EMPLOYEE_POLICY_PREVIEW_DATA,
                payload: result.response ? result.response : '',
            })
        }
    }
}
// export const getEmployeeViewData = (id, userId) => {
//     return async (dispatch) => {
//         let result = await fetchData({ url: `/employee/policy/view?id=${id}&userId=${userId}`, method: 'GET' });
//         if (result && result.status === 1) {
//             dispatch({
//                 type: actionTypes.EMPLOYEE_POLICY_PREVIEW_DATA,
//                 payload: result.response ? result.response : '',
//                 readStatus: result.readStatus ? result.readStatus : ''
//             })
//         } else {
//             dispatch({
//                 type: actionTypes.BOTTOM_T_W_AL,
//                 payload: { show_hide: true, text: result.response, alignment: "centre" }
//             });
//             dispatch({
//                 type: actionTypes.EMPLOYEE_POLICY_DOCUMENT_PREVIEW,
//                 payload: ''
//             });

//         }
//     }
// }
export const assignPolicyModal = (id, type) => {
    return {
        type: actionTypes.SHOW_GROUP_POLICY_ASSIGN_MODAL,
        payload: { id: id ? id : '', userType: type ? type : '' }
    }
}
export const getUserPolicyList = (id) => async (dispatch) => {
    let result = await fetchData({ url: `/user/employee/policies?id=${id}`, method: 'GET' });
    if (result && result.status === 1) {
        dispatch({
            type: actionTypes.POLICY_DOCUMENT_LIST,
            payload: result.response ? result.response : []
        })
    } else {
        dispatch({
            type: actionTypes.POLICY_DOCUMENT_LIST,
            payload: []
        })
    }
}
export const clearPolicyList = () => {
    return {
        type: actionTypes.POLICY_DOCUMENT_LIST,
        payload: [],
        count: 0
    }
}
export const getAssignedDetails = (data) => {
    return {
        type: actionTypes.SELETED_POLICY_DOCUMENTS,
        payload: data && Object.keys(data).length > 0 ? data : {},
    }
}
// export const assignPolicyToGroupUsers = (assignData, groupId, props) => {
//     let data = {};
//     data['selectedDocuments'] = assignData && assignData.selectedPolicies && assignData.selectedPolicies.length > 0 ? JSON.stringify(assignData.selectedPolicies) : '';
//     data['dueDate'] = assignData && assignData.dueDate ? new Date(assignData.dueDate) : ''
//     data['groupId'] = groupId ? groupId : '';
//     return (dispatch) => {
//         let response = fetchData({url:'/user/group/policy/assign',method: 'POST',data: data});
//         response.then((res) => {
//             if (res && res.status === 1) {
//                 dispatch({
//                     type: actionTypes.BOTTOM_T_W_AL,
//                     payload: { show_hide: true, text: res.response, alignment: "centre" }
//                 });
//                 dispatch({
//                     type: actionTypes.SHOW_GROUP_POLICY_ASSIGN_MODAL,
//                     payload: ''
//                 })
//                 props.history.push('/groups');
//             } else {
//                 dispatch({
//                     type: actionTypes.BOTTOM_T_W_AL,
//                     payload: { show_hide: true, text: res.response, alignment: "centre" }
//                 })
//             }
//         }).catch((err) => {
//             dispatch({
//                 type: actionTypes.BOTTOM_T_W_AL,
//                 payload: { show_hide: true, text: 'Somthing went wrong', alignment: "centre" }
//             })
//         });

//     }
// }
export const addDocumentToCompanyPolicy = (fileId, userId,category) => {
    return async (dispatch) => {
        if (fileId && fileId != '' && fileId !== undefined && userId && userId !== '' && userId !== undefined) {
            let result = await fetchData({ url: `/user/add/company/policy?userId=${String(userId)}&fileId=${String(fileId)}&category=${category}`, method: 'GET' });
            if (result && result.status === 1) {
                dispatch(checkEmpPolicy(fileId))
                dispatch({
                    type: actionTypes.ADD_POLICY_CONFIRMATION_MODAL,
                    payload: { userId: '', fileId: '' }
                })
            
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: result.response, alignment: "centre" }
                })
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Invalid request', alignment: "centre" }
                })
            }
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Invalid request', alignment: "centre" }
            })
        }
    }
}
export const assignPolicyToGroupEmployee = (assignData, groupId, props) => async (dispatch) => {
    let data = {};
    data['selectedDocuments'] = assignData && assignData.selectedPolicies && assignData.selectedPolicies.length > 0 ? JSON.stringify(assignData.selectedPolicies) : '';
    data['dueDate'] = assignData && assignData.dueDate ? new Date(assignData.dueDate) : ''
    data['groupId'] = groupId ? groupId : '';

    let res = await fetchData({ url: '/user/group/policy/assign', method: 'POST', data: data });
    if (res && res.status === 1) {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: res.response, alignment: "centre" }
        });
        dispatch({
            type: actionTypes.SHOW_GROUP_POLICY_ASSIGN_MODAL,
            payload: ''
        })
        props.history.push('/groups');
    } else {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: res.response, alignment: "centre" }
        })
    }
}
export const assignPolicyToEmployee = (assignData, id, props) => async (dispatch) => {
    let data = {};
    data['selectedDocuments'] = assignData && assignData.selectedPolicies && assignData.selectedPolicies.length > 0 ? JSON.stringify(assignData.selectedPolicies) : '';
    data['dueDate'] = assignData && assignData.dueDate ? new Date(assignData.dueDate) : ''
    data['userId'] = id ? id : '';
    let res = await fetchData({ url: '/employee/assign/policy', method: 'POST', data: data });
    if (res && res.status === 1) {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: res.response, alignment: "centre" }
        });
        dispatch({
            type: actionTypes.SHOW_GROUP_POLICY_ASSIGN_MODAL,
            payload: ''
        })
        props.history.push('/employees');
    } else {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: res.response, alignment: "centre" }
        })
    }
}
// export const userAcceptPolicy = (docId, userId) => {
//     return async (dispatch) => {
//         if (docId && docId != '' && docId !== undefined && userId && userId !== '' && userId !== undefined) {
//             let result = await fetchData({ url: `/user/policy/read?userId=${String(userId)}&docId=${String(docId)}`, method: 'GET' });
//             if (result && result.status === 1) {
//                 dispatch({
//                     type: actionTypes.BOTTOM_T_W_AL,
//                     payload: { show_hide: true, text: result.response, alignment: "centre" }
//                 });
//                 dispatch({
//                     type: actionTypes.EMPLOYEE_POLICY_DOCUMENT_PREVIEW,
//                     payload: ''
//                 });

//             } else {
//                 dispatch({
//                     type: actionTypes.BOTTOM_T_W_AL,
//                     payload: { show_hide: true, text: 'Invalid request', alignment: "centre" }
//                 })
//             }
//         } else {
//             dispatch({
//                 type: actionTypes.BOTTOM_T_W_AL,
//                 payload: { show_hide: true, text: 'Invalid request', alignment: "centre" }
//             })
//         }
//     }
// }
export const policyReadNotifyModal = (cond) => {
    return {
        type: actionTypes.POLICY_UNREAD_NOTIFY_MODAL,
        payload: cond ? cond : false
    }
}
export const downloadPolicy = (id) => async (dispatch) => {
    if (id && id !== undefined && id !== '') {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: 'Generating PDF file', alignment: "bottom" }
        });
        let result = await fetchData({ url: `/user/policy-document/download?id=${id} `, method: 'GET' });
        if (result && result.status === 1) {
            dispatch({ type: actionTypes.PAGE_LOADER_SUCCESS });
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: false, text: '', alignment: "centre" }
            });
            var oReq = new XMLHttpRequest();
            var URLToPDF = result.response;
            let url = window.location.origin + new URL(URLToPDF).pathname;
            var filename = result.filename ? (result.filename).split(' ').join('-').toLowerCase() : 'Document';
            oReq.open("GET", url, true);
            oReq.responseType = "blob";
            oReq.onload = function () {
                var file = new Blob([oReq.response], {
                    type: 'application/pdf'
                });
                saveAs(file, filename + ".pdf");
            };
            oReq.send();
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: result.response, alignment: "centre" }
            })
        }
    } else {
        dispatch({
            type: actionTypes.BOTTOM_T_W_AL,
            payload: { show_hide: true, text: 'Invalid request', alignment: "centre" }
        })
    }
}
export const hidePolicy = (userId, policyId,skip, itemPerPage, activePage, searchValue) => {
    return async (dispatch) => {
        if (policyId && policyId != '' && policyId !== undefined && userId && userId !== '' && userId !== undefined) {
            let result = await fetchData({ url: `/company/hide/policy`, method: 'POST', data: { userId, policyId } });
            if (result && result.status === 1) {
                dispatch(getPolicyDocuments(skip, itemPerPage, activePage, searchValue, userId));
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: result.response, alignment: "centre" }
                })
            } else {
                dispatch({
                    type: actionTypes.BOTTOM_T_W_AL,
                    payload: { show_hide: true, text: 'Invalid request', alignment: "centre" }
                })
            }
        } else {
            dispatch({
                type: actionTypes.BOTTOM_T_W_AL,
                payload: { show_hide: true, text: 'Invalid request', alignment: "centre" }
            })
        }
    }
}

export const uploadFilePolicy = (obj) => {
    const  userId = sessionStorage.userId ? sessionStorage.userId : '';
    let formData = new FormData();
    formData.append('file', obj.pdfFile);
    formData.append('fileName', obj.fileName);
    formData.append('userId', userId);
    return async (dispatch) => {
        const res = await fetchData({ url: '/user/staffpolicy/upload', method: 'POST', data: formData })
        if (res.status === 1) {
            dispatch(getPolicyDocuments())
            dispatch(openUploadFileModal())
            
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}