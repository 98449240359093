import * as actionTypes from '../actions/types.js';
import { NotUsedVariables } from '../services';
const notUsedVariablesArr = NotUsedVariables();

export default (state = {
    selectedFile: {}, comapareTwoFile: {}, editData: {}, selectedDetails: {}, clickedFolder: [], exportList: [],
    downloadas: 'pdf', downloadtype: 'potrait', downloadFile: {}, editorContent: null,footerContent:'',headerContent:'', popupbreadcrumbs: [], moveFilesData: [],
    navSidebar: false, recentActivities: [], variableActiveTab: "empty-tab", clientVariables: [], searched_value: '',
    notUsedVariables: [], show_dbl_click: false, doc_name: '', open_hide_link: false, template_list_arr: [], input_searched_value: '',
    Saved_docs_list: [], saved_search_val: '', count: 0, skip: 0, activePage: 1, itemPerPage: 10, saved_edit_view_dat: {},
    saved_docs_name: '', show_hide_pop_for_saved: false, get_original_file_data: {}, saved_docs_edior_content: '',
    show_hide_pop_for_saved_DOCS_EDIT: false, saved_docs_full_data: {}, list: [], sampleFilesList: [], docaccessModal: false,
    documentList: [], reviewModal: false, reviewFile: {}, reviewData: {}, modalType: '', notesList: [], clickedFile: {}, guideModal: false, guideData: "", reportModal: false, reportData: {},
    skipDiscription: true, skipNotes: true, showConfirmModal: false, fileId: '', userId: '',category:'', referenceDocuments: [], referenceDocumentView: {},
    conformationModal: false, detailsModal: false, sidebarIds: [], description: '', refDocModal: false, referenceOption: [], selectedRefDoc: {}, editor: {},
    editOption: {}, refDocId: '', refDeleteModal: false, backupModal: false, backupData: [], viewBackupModal: false, backupContent: '', docxUrl: '',
    docxModal: false, viewType: 'grid', multipleClickedFile: [], bulkReviewModal: false, bulkReviewFile: [], bulkModalType: '', clickedFileId: '', viewDocumentRef: [], previousFile_Content: '', viewBracketModal: false,viewUpdateModal:false, bracket_modal_type: '', bracket_modal_item: {}, viewIframeModal: false, iframe_modal_src: '', bactivePage: 1, bskip: 0, binDocumentLists: [], collectiontype: 'userfiles', bcount: 0, bitemPerPage: 25, viewTouchedFile: {},QAModal:false,ADDQAModal:false,QAdata:[],editQAdata:{},testRequired:0,empPolicy:0,selectRestoreModal:false

}, action) => {
    switch (action.type) {
        case actionTypes.EDIT_SAVED_DOCS_NAME_POP:
            return {
                ...state, show_hide_pop_for_saved_DOCS_EDIT: action.payload
            }
        case actionTypes.SET_DOCS_EDITED_DATA_BY_ID:
            return {
                ...state, saved_docs_full_data: { ...action.payload }
            }
        case actionTypes.SET_EDITOR_CONTENT_FOR_SAVDD_DOCS:
            return {
                ...state, saved_docs_edior_content: action.payload
            }
        case actionTypes.SET_SAVED_DOCS_EDITOR_CONTENT:
            return {
                ...state, saved_docs_edior_content: action.payload
            }
        case actionTypes.CLEAR_SAVED_DOCS_ADD_DATA:
            return {
                ...state, get_original_file_data: { ...action.payload }
            }
        case actionTypes.GET_ORIGINAL_FILE_DATA:
            return {
                ...state, get_original_file_data: { ...action.payload }
            }
        case actionTypes.SHOW_POP_FR_SAVED_DOCS:
            return {
                ...state, show_hide_pop_for_saved: action.payload
            }
        case actionTypes.POP_FOR_SAVED_DOCS_NAME:
            return {
                ...state, saved_docs_name: action.payload
            }
        case actionTypes.SET_ATTR_FOR_SAVED_DOCS:
            return {
                ...state, activePage: action.page_no, saved_search_val: action.serachVal, skip: action.skip, itemPerPage: Number(action.itemPerPage)
            }
        case actionTypes.LIST_SAVED_DOCS:
            return {
                ...state, Saved_docs_list: [...action.payload], count: action.count ? action.count : 0
            }
        case actionTypes.SEARCH_VAL_SAVED_DOCS:
            return {
                ...state, saved_search_val: action.payload
            }
        case actionTypes.EDIT_VIEW_SAVED_DOCS:
            return {
                ...state, saved_edit_view_dat: { ...action.payload }
            }
        case actionTypes.CLEAR_SAVED_DOCS:
            return {
                ...state, Saved_docs_list: [...action.payload]
            }
        case actionTypes.GET_COMP_TEMPLATE_LIST:
            return {
                ...state,
                template_list_arr: action.payload
            };
        case actionTypes.OPEN_CLOSE_LINK_POP:
            return {
                ...state,
                open_hide_link: action.payload
            };
        case actionTypes.ADD_FOLDER_NAME:
            return {
                ...state,
                folderName: action.payload,
                folderId: sessionStorage.getItem('rightClickedFolderId') ? sessionStorage.getItem('rightClickedFolderId') : sessionStorage.getItem('clickedFolderId')
            };
        case actionTypes.CLEAR_FOLDER_NAME:
            return {
                ...state,
                folderName: action.payload,
            };
        case actionTypes.CLEAR_DOWNLOAD_DOC:
            return {
                ...state,
                downloadFile: action.payload
            };
        case actionTypes.ADD_FILE_NAME:
            return {
                ...state,
                editData: { ...state.editData, ...action.payload }
            };
        case actionTypes.CLEAR_EDIT_DOCS_DATA:
            return {
                ...state,
                editData: { ...action.payload }
            };
        case actionTypes.GET_ALL_DOCUMENT:
            return {
                ...state,
                list: action.payload
            };

        case actionTypes.CLEAR_DOCUMENT:
            return {
                ...state,
                list: [...action.payload]
            };

        case actionTypes.GET_DOCUMENT_BY_ID:
            return {
                ...state,
                list: action.payload
            };

        case actionTypes.CLICKED_DOCUMENT:
            return {
                ...state,
                clickedFolder: [...state.clickedFolder, action.payload]
            };

        case actionTypes.CLEAR_CLICKED_DOCUMENT:
            return {
                ...state,
                clickedFolder: action.payload
            };

        case actionTypes.ADD_CLICKED_DOCUMENT:
            return {
                ...state,
                clickedFolder: action.payload
            };

        case actionTypes.PATH_CHANGING:
            return {
                ...state,
                clickedFolder: state.clickedFolder.splice(0, action.index + 1)
            };

        case actionTypes.UPDATE_LIST:
            return {
                ...state,
                list: [action.payload, ...state.list]
            };

        case actionTypes.UPDATE_RENAME_MODAL:
            return {
                ...state,
                list: state.list.map((item, i) => item._id === action.payload.folderId ?
                    { ...item, name: action.payload.folderName, action_by: action.payload && action.payload.action_by ? action.payload.action_by : '' } : item)
            };
        case actionTypes.UPDATE_FILE_EDIT_MODAL:
            return {
                ...state,
                list: state.list.map((item, i) => item._id === action.payload.fileId ?
                    { ...item, name: action.payload.fileName, policyNumber: action.payload.policyNumber } : item)
            };

        case actionTypes.SELECTED_FILE:
            return {
                ...state,
                //selectedFile: action.payload,
                fileOpen: !state.fileOpen,
                downloadFile: action.payload
            };

        case actionTypes.RECENT_FILES:
            return {
                ...state,
                recentFiles: action.payload,
            };

        case actionTypes.COMPARE_FILE:
            return {
                ...state,
                comapareFile: action.payload,
                showCompareModel: !state.showCompareModel
            };

        case actionTypes.GET_COMPARE_FILE:
            return {
                ...state,
                comapareTwoFile: { ...state.comapareTwoFile, ...action.payload },
            };

        case actionTypes.UPDATE_RECENT_FILES:
            return {
                ...state,
                recentFiles: [action.payload, ...state.recentFiles]
            };

        case actionTypes.SELECTED_DETAILS:
            return {
                ...state,
                selectedDetails: {
                    clickedId: action.id,
                    clickedName: action.name
                }
            };

        case actionTypes.DOCUMENT_DELETE_MODEL:
            return {
                ...state,
                showDocumetDeleteModal: !state.showDocumetDeleteModal,
            };

        case actionTypes.DELETE_DOCUMENT__REMOVE:
            return {
                ...state,
                list: state.list.filter(e => e.uId !== action.documentId),
                // recentFiles: state.recentFiles.filter(e => e.mainParentId !== action.documentId)
            };

        case actionTypes.DELETE_FILE__REMOVE:
            return {
                ...state,
                list: state.list.filter(e => e._id !== action.documentId),
                // recentFiles: state.recentFiles.filter(e => e._id !== action.documentId)
            };

        case actionTypes.FILE_DELETE_MODEL:
            return {
                ...state,
                showFileDeleteModal: !state.showFileDeleteModal
            };
        case actionTypes.UPDATE_FILE_LIST:
            return {
                ...state,
                list: state && state.list && state.list.map((item) => item._id === action.payload.id ? { ...item, updateAvailable: false, content: action.payload.content } : item)
            };
        case actionTypes.UPDATE_DOCUMENT_FILE:
            return {
                ...state,
                list: state.list.map((item) => item._id === action.payload.id ? { ...item, filename: action.payload.filename, policyNumber: action.payload.policyNumber, name: action.payload.name, format: action.payload.format, content: action.payload.content, action_by: action.payload && action.payload.action_by ? action.payload.action_by : '' } : item)
            };
        case actionTypes.ADD_EXPORT_LIST:
            let index = state.exportList.map(function (e) { return e.id; }).indexOf(action.payload._id);
            if (index > -1) {
                state.exportList.splice(index, 1);
            } else {
                let exportObj = {};
                exportObj.id = action.payload._id;
                exportObj.type = action.payload.type;
                exportObj.name = action.payload.name;
                state.exportList.push(exportObj);
            }
            return {
                ...state,
                exportList: state.exportList
            };
        case actionTypes.ADD_EXPORT_ALLLIST:
            // state.exportList = action.payload;
            let list = action.payload && action.payload.length > 0 ? action.payload.map(e => {
                return {
                    id: e._id,
                    type: e.type,
                    name: e.name
                }
            }) : [];
            return {
                ...state,
                exportList: list
            }
        case actionTypes.ADD_EXPORT_FILE_FORMAT:
            return {
                ...state,
                downloadas: action.payload
            }
        case actionTypes.ADD_EXPORT_FILE_TYPE:
            return {
                ...state,
                downloadtype: action.payload
            }
        case actionTypes.UPDATE_DOCUMENT_CONTENT:
            return {
                ...state,
                editData: { ...state.editData, ...action.payload },
                comapareTwoFile: { ...state.comapareTwoFile, ...action.payload },
            };
        case actionTypes.UPDATE_ADMIN_DOCUMENT_CONTENT:
            return {
                ...state,
                comapareTwoFile: { ...state.comapareTwoFile, ...action.payload },
            };
        case actionTypes.UPDATE_DOCUMENT_LIST:
            return {
                ...state,
                list: state.list.map((item, i) => item._id === action.payload ?
                    { ...item, review_status: 'Up to Date' } : item)
            };
        case actionTypes.UPDATE_MULTIPLE_DOCUMENT_LIST:
            return {
                ...state,
                list: state.list.map(e1 => {
                    if (action.payload.includes(e1._id)) {
                        e1.review_status = 'Up to Date'
                    }
                    return e1
                })
            };
        case actionTypes.SET_EDITOR_CONTENT:
            return {
                ...state,
                editorContent: action.payload
            }
        case actionTypes.SET_DOWNLOAD_HEADER:
            return {
                ...state,
                headerContent: action.payload
            }
        case actionTypes.SET_DOWNLOAD_FOOTER:
            return {
                ...state,
                footerContent: action.payload
            }
        case actionTypes.UPDATE_RESTORE_CONTENT:
            if (state.downloadFile && state.downloadFile.content) state.downloadFile.content = action.payload;
            return {
                ...state,
                list: state.list && state.list.length > 0 && state.list.map((item, i) => item._id === action.fileId ?
                    { ...item, content: action.payload } : item),
                downloadFile: state.downloadFile,
                editorContent: action.payload
            }
        case actionTypes.DOCUMENT_INFO_SIDEBAR:
            return {
                ...state,
                navSidebar: action.payload
            }
        case actionTypes.DOCUMENT_RECENT_ACTIVITIES:
            return {
                ...state,
                recentActivities: action.payload
            }
        case actionTypes.SHOW_DBL_CLICK:
            return {
                ...state,
                doc_name: action.payload ? action.payload.documentName ? action.payload.documentName : '' : '',
                show_dbl_click: action.payload ? action.payload.show_dbl_click ? action.payload.show_dbl_click : false : false,
            }
        case actionTypes.SET_DOC_DBL_VALUE:
            return {
                ...state,
                doc_name: action.payload
            }
        case actionTypes.SET_SEARCHED_VALUE:
            return {
                ...state,
                searched_value: action.payload
            }
        case actionTypes.SET_SEARCHED_VALUE_FOR_INPUT:
            return {
                ...state,
                input_searched_value: action.payload
            }
        case actionTypes.ST_DBL_CLK_FALSE:
            return {
                ...state,
                show_dbl_click: action.payload ? action.payload : false
            }
        case actionTypes.CLIENT_VARIABLES_LIST:
            var notUsedVariableLists = [];
            var newNotUsedLists = notUsedVariablesArr.filter(function (cv) {
                return !action.payload.find(function (e) {
                    return e.key == cv.key;
                });
            });
            return {
                ...state,
                clientVariables: action.payload,
                notUsedVariables: newNotUsedLists && newNotUsedLists.length > 0 ? newNotUsedLists : []
            }
        case actionTypes.FILTER_DOCUMENT_VARIABLES:
            const searchVal = action.payload.value.toLowerCase();
            const clientVariablesArr = action.payload.variables;
            var clientVariableLists = [];
            var notUsedVariableLists = [];
            for (var i = 0; i < clientVariablesArr.length; i++) {
                if (clientVariablesArr[i].key.toLowerCase().indexOf(searchVal) > -1)
                    clientVariableLists.push(clientVariablesArr[i]);
            }
            for (var j = 0; j < notUsedVariablesArr.length; j++) {
                if (notUsedVariablesArr[j].key.toLowerCase().indexOf(searchVal) > -1)
                    notUsedVariableLists.push(notUsedVariablesArr[j]);
            }
            return {
                ...state,
                clientVariables: clientVariableLists,
                notUsedVariables: notUsedVariableLists && notUsedVariableLists.length > 0 ? notUsedVariableLists : []
            }
        case actionTypes.SAMPLE_FILES_LIST:
            return {
                ...state,
                sampleFilesList: [...action.payload]
            }
        case actionTypes.DOCUMENT_ACCESS_MODAL:
            return {
                ...state,
                docaccessModal: !state.docaccessModal
            }
        case actionTypes.CLEAR_SEARCH_VALUE:
            return {
                ...state,
                searched_value: action.payload ? action.payload : "",
                input_searched_value: action.payload ? action.payload : ""
            }
        case actionTypes.RECENTLY_UPDATED_DOCUMENTS:
            return {
                ...state,
                documentList: action.payload,
                count: action.count ? action.count : 0
            }
        case actionTypes.REVIEW_MODAL_POPOP:
            return {
                ...state,
                reviewModal: !state.reviewModal,
                reviewFile: action.payload ? action.payload : {},
                modalType: action.method ? action.method : ''
            }
        case actionTypes.DOCUMENT_REVIEW_DATA:
            return {
                ...state,
                reviewData: action.payload && Object.keys(action.payload).length !== 0 ? { ...state.reviewData, ...action.payload } : {},
            }
        case actionTypes.DOCUMENT_UPDATE_NOTES_LIST:
            return {
                ...state,
                notesList: action.payload
            }
        case actionTypes.GET_CLICKED_FILE:
            return {
                ...state,
                clickedFile: action.payload ? action.payload : {}
            }
        case actionTypes.GUIDE_MODAL_POPOP:
            return {
                ...state,
                guideModal: !state.guideModal
            }
        case actionTypes.GET_GUIDE_DATA:
            return {
                ...state,
                guideData: action.payload ? action.payload : ""
            }
        case actionTypes.REPORT_MODAL_POPUP:
            return {
                ...state,
                reportModal: action.payload
            }
        case actionTypes.DOCUMENT_REPORT_DATA:
            return {
                ...state,
                reportData: action.payload && Object.keys(action.payload).length !== 0 ? { ...state.reportData, ...action.payload } : {},
            }
        case actionTypes.SKIP_DISCRIPTION:
            return {
                ...state,
                skipDiscription: action.payload ? action.payload : !state.skipDiscription
            }
        case actionTypes.SKIP_NOTES:
            return {
                ...state,
                skipNotes: action.payload ? action.payload : !state.skipNotes
            }
        case actionTypes.ADD_POLICY_CONFIRMATION_MODAL:
            return {
                ...state,
                showConfirmModal: !state.showConfirmModal,
                fileId: action.payload && action.payload.fileId ? action.payload.fileId : '',
                userId: action.payload && action.payload.userId ? action.payload.userId : '',
                category: action.payload && action.payload.category ? action.payload.category : ''
            }
        case actionTypes.REFERENCE_DOCUMENTS:
            return {
                ...state,
                referenceDocuments: action.payload,
                sidebarIds: action.sidebarIds
            }
        case actionTypes.REFERENCE_DOCUMET_VIEW:
            return {
                ...state,
                referenceDocumentView: action.payload
            }
        case actionTypes.CONFORMATION_MODAL:
            return {
                ...state,
                conformationModal: action.payload,
                description: action.description
            }
        case actionTypes.DETAILS_MODAL:
            return {
                ...state,
                detailsModal: !state.detailsModal
            }
        case actionTypes.REFERENCE_DOCUMENT_MODAL:
            return {
                ...state,
                refDocModal: !state.refDocModal,
                editor: action.payload,
                editOption: action.editOption
            }
        case actionTypes.REFERENCE_OPTION:
            return {
                ...state,
                referenceOption: action.payload
            }
        case actionTypes.SELECTED_REF_DOC:
            return {
                ...state,
                selectedRefDoc: action.payload
            }
        case actionTypes.REF_DELETE_MODAL:
            return {
                ...state,
                refDeleteModal: !state.refDeleteModal,
                refDocId: action.payload
            }
        case actionTypes.BACKUP_MODAL:
            return {
                ...state,
                backupModal: !state.backupModal,
            }
        case actionTypes.BACKUP_FILE_DATA:
            return {
                ...state,
                backupData: action.payload
            }
        case actionTypes.VIEW_BACKUP_MODAL:
            return {
                ...state,
                viewBackupModal: !state.viewBackupModal,
                backupContent: action.payload
            }
        case actionTypes.VIEW_DOCX_MODAL:
            return {
                ...state,
                docxModal: !state.docxModal,
                docxUrl: action.payload ? action.payload : ''
            }
        case actionTypes.TOGGLE_PAGE_VIEW:
            return {
                ...state,
                viewType: action.payload,
            }
        case actionTypes.UPDATE_FOLDER_ICON_COLOR:
            let data = [...state.list];
            let updatedList = [];
            updatedList = data.map(e => {
                if (e._id === action.payload.id) {
                    e.iconcolour = action.payload.colour
                }
                return e;
            })
            return {
                ...state,
                list: updatedList,
            }
        case actionTypes.GET_CLICKED_MULTIPLE_FILE:
            return {
                ...state,
                multipleClickedFile: [...action.payload],
            }
        case actionTypes.BULK_REVIEW_MODAL_POPOP:
            return {
                ...state,
                bulkReviewModal: !state.bulkReviewModal,
                bulkReviewFile: action.payload ? action.payload : [],
                bulkModalType: action.method ? action.method : ''
            }
        case actionTypes.SELECTED_RESTORE_MODAL:
            return {
                ...state,
                selectRestoreModal: !state.selectRestoreModal,
            }
        case actionTypes.GET_DOC_REF_LIST:
            return {
                ...state,
                viewDocumentRef: action.payload,
            }
        case actionTypes.PREVIOUS_FILE_CONTENT:
            return {
                ...state,
                previousFile_Content: action.payload,
            }
        case actionTypes.BRACKET_MODAL_POPOP:
            return {
                ...state,
                viewBracketModal: !state.viewBracketModal,
                bracket_modal_item: action.payload && action.payload.item ? action.payload.item : '',
                bracket_modal_type: action.payload && action.payload.type ? action.payload.type : ''
            }
        case actionTypes.UPDATE_MODAL_POPOP:
            return {
                ...state,
                viewUpdateModal: !state.viewUpdateModal,
                bracket_modal_item: action.payload && action.payload.item ? action.payload.item : '',
                bracket_modal_type: action.payload && action.payload.type ? action.payload.type : ''
            }
        case actionTypes.IFRAME_MODAL_POPOP:
            return {
                ...state,
                viewIframeModal: !state.viewIframeModal,
                iframe_modal_src: action.payload && action.payload.src ? action.payload.src : ''
            }
        case actionTypes.BIN_TABLE_ATTRIBUTES:
            return {
                ...state,
                bactivePage: action.page_no,
                bskip: action.skip,
                collectiontype: action.collection
            }
        case actionTypes.BIN_DOCUMENTS_LIST:
            return {
                ...state,
                binDocumentLists: action.payload,
                bcount: action.count
            }
        case actionTypes.CLEAR_BIN_DOCUMENTS_LIST:
            return {
                ...state,
                binDocumentLists: action.payload,
                bcount: action.count
            }
        case actionTypes.BIN_UPDATE_DLIST:
            return {
                ...state,
                binDocumentLists: state.binDocumentLists.filter(e => e._id !== action.payload)
            }
        case actionTypes.UPDATE_FILES_LIST:
            return {
                ...state,
                moveFilesData: []
            }
        case actionTypes.POPUP_BREADCRUMBS:
            if (action.folderId === '' && action.folderName === '') {
                state.popupbreadcrumbs = [];
            } else {
                var indexx = state.popupbreadcrumbs.findIndex((e) => { return e.folderId.toString() === action.folderId.toString() });
                if (indexx !== -1) {
                    state.popupbreadcrumbs.splice(indexx + 1);
                } else {
                    state.popupbreadcrumbs.push({ 'folderId': action.folderId, 'folderName': action.folderName });
                }
            }
            return {
                ...state,
                popupbreadcrumbs: state.popupbreadcrumbs
            }
        case actionTypes.UPDATE_FILES_LIST:
            return {
                ...state,
                moveFilesData: []
            }
        case actionTypes.UPDATE_DOCUMENT_STATUS:
            if (typeof action.payload === 'string' || action.payload instanceof String) {
                return {
                    ...state,
                    list: state.list.map((item) => item._id === action.payload ? { ...item, 'status_type': action.status } : item)
                }
            } else {
                if (action.payload.length > 0) {
                    action.payload.forEach((item) => {
                        let index = state.list.findIndex((e) => { return e._id.toString() === item.toString() });
                        if (index !== -1) {
                            if (action.status === 'Deleted') {
                                state.list.splice(index, 1);
                            } else {
                                state.list[index].status_type = action.status;
                            }
                        }
                    });
                }
                return {
                    ...state,
                    list: state.list
                }
            }
        case actionTypes.GET_TOUCHED_FILE:
            return {
                ...state,
                viewTouchedFile: action.payload && action.payload ? action.payload : {}
            }

            //QA
            case actionTypes.QUESTION_MODAL_POPUP:
                return {
                    ...state,
                    QAModal: action.payload
                }
            case actionTypes.ADD_QUESTION_MODAL_POPUP:
                return {
                    ...state,
                    ADDQAModal: action.payload
                }
            case actionTypes.GET_QUESTION_DATA:
                return {
                    ...state,
                    QAdata: action.payload
                }
            case actionTypes.EDIT_QUESTION:
                return {
                    ...state,
                    editQAdata: action.payload
                }
            case actionTypes.GET_TEST_REQ_DATA:
                return {
                    ...state,
                    testRequired: action.payload
                }
            case actionTypes.GET_EMP_POLICY_DATA:
                return {
                    ...state,
                    empPolicy: action.payload
                }
        default:
            return state;
    }
};

