import * as actionTypes from './types';
import { saveAs } from 'file-saver';
import fetchData from '../services';

export const templateModalFunction = () => ({ type: actionTypes.USE_TEMPLATE_MODAL });

export const saveToDraft = (details, history) => {
    const file = sessionStorage.selectedFile ? JSON.parse(sessionStorage.selectedFile) : {};
    const data = {
        fileName: details.fileName,
        root: JSON.stringify(details.rootpath),
        userId: sessionStorage.userId ? sessionStorage.userId : '',
        fileId: file.uId ? file.uId : '',
        parentId: details.rootpath && (details.rootpath).length > 0 ? details.rootpath[(details.rootpath).length - 1] : '',
        mainParentId: details.mainFolder
    }
    return async (dispatch) => {
        if (details.fileName) {
            const res = await fetchData({ url: '/user/savefile/toDraft', method: 'POST', data })
            if (res.status === 1) {
                dispatch({ type: actionTypes.USE_TEMPLATE_MODAL })
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'File Created', alignment: "centre" } });
                dispatch(openMyfile(res.response, history))
            } else {
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
            }
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'Please Enter File Name', alignment: "centre" } });
        }
    }
}

export const getFolderData = (id, searchVal) => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.PAGE_LOADER_PENDING });
        const folderId = id ? id : "",
            userId = sessionStorage.userId ? sessionStorage.userId : '',
            searchValue = searchVal ? searchVal : '';
        const res = await fetchData({ url: `/user/get/myfiles?folderId=${folderId}&userId=${userId}&searchValue=${searchValue}`, method: 'GET' });
        if (res.status === 1) {
            dispatch({ type: actionTypes.MYFILES_ALL_DATA, payload: res.response, root: res.root })
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const openMyfile = (id, history) => {
    return async (dispatch) => {
        dispatch({ type: actionTypes.SELECTED_MYFILE, payload: id });
        sessionStorage.setItem('myfilesId', id);
        const userId = sessionStorage.userId ? sessionStorage.userId : '';
        const res = await fetchData({ url: `/user/get/myfiles/file?fileId=${id}&userId=${userId}`, method: 'GET' });
        if (res.status === 1) {
            dispatch({ type: actionTypes.MYFILES_EDITOR_FILE, payload: res.response })
            sessionStorage.setItem('myfilesContent', res.response.content);
            dispatch(setEditorContent(res.response.content))
            history.push('/myDocuments/editor')
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const createFolderModalFuc = (id) => ({ type: actionTypes.CREATE_FOLDER_MODAL, payload: id })

export const getFoldersData = () => {
    return async (dispatch) => {
        const userId = sessionStorage.userId ? sessionStorage.userId : '';
        const res = await fetchData({ url: `/user/get/myfiles/folders?userId=${userId}`, method: "GET" });
        if (res.status === 1) {
            dispatch({ type: actionTypes.MYFILES_FOLDERS_LIST, payload: res.response })
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const createMyFilesFolder = (details, root, page) => {
    return async (dispatch) => {
        const data = {
            ...details,
            root: root ? JSON.stringify(root) : '',
            userId: sessionStorage.userId ? sessionStorage.userId : "",
        }
        const res = await fetchData({ url: '/user/create/myfole/folder', method: "POST", data });
        if (res.status === 1) {
            if (page === 'documentPage') {
                dispatch({ type: actionTypes.CREATE_MYFILES_FOLDER_MODAL })
                dispatch(getFoldersData())
            } else {
                const id = sessionStorage.myfilesSelectedFolderId ? sessionStorage.myfilesSelectedFolderId : '';
                dispatch(createFolderModalFuc())
                dispatch(getFolderData(id))
            }
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'New Folder Created Sucessfully', alignment: "centre" } });
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const toggleShowbutton = (val) => ({ type: actionTypes.ADD_MYFILES_FOLDER, payload: val });


export const createFolderModal = () => ({ type: actionTypes.CREATE_MYFILES_FOLDER_MODAL });

export const getMainSubFolder = (folderId, key) => {
    return async (dispatch) => {
        const mainFolderId = folderId ? folderId : '',
            userId = sessionStorage.userId ? sessionStorage.userId : '';
        const res = await fetchData({ url: `user/get/main/subFolder?userId=${userId}&folderId=${mainFolderId}`, method: 'GET' })
        if (res.status === 1) {
            if (key === 'main') {
                dispatch({ type: actionTypes.MAIN_SUBFOLDERS, payload: res.response })
            }
            if (key === 'subOne') {
                dispatch({ type: actionTypes.SUB_ONE_SUBFOLDERS, payload: res.response })
            }
            if (key === 'subTwo') {
                dispatch({ type: actionTypes.SUB_TWO_SUBFOLDERS, payload: res.response })
            }
            if (key === 'subThree') {
                dispatch({ type: actionTypes.SUB_THREE_SUBFOLDERS, payload: res.response })
            }
            if (key === 'subFour') {
                dispatch({ type: actionTypes.SUB_FOUR_SUBFOLDERS, payload: res.response })
            }
            if (key === 'subFive') {
                dispatch({ type: actionTypes.SUB_FIVE_SUBFOLDERS, payload: res.response })
            }
        }
    }
}
export const getSubOneFolder = (folderId) => {
    return async (dispatch) => {
        const mainFolderId = folderId ? folderId : '',
            userId = sessionStorage.userId ? sessionStorage.userId : '';
        const res = await fetchData({ url: `user/get/subOne/subFolder?userId=${userId}&folderId=${mainFolderId}`, method: 'GET' })
        if (res.status === 1) {
            dispatch({ type: actionTypes.SUB_ONE_SUBFOLDERS, payload: res.response })
        }
    }
}
export const rootarray = (arr) => ({ type: actionTypes.MYFILES_ROOT_ARRAY, payload: [...arr] });

export const toggleRenameModal = (id, type) => {
    return async (dispatch) => {
        if (id) {
            const res = await fetchData({ url: `/user/myfiles/getFolderName?id=${id}&type=${type}`, method: 'GET' });
            if (res.status === 1) {
                dispatch({ type: actionTypes.MYFILES_RENAME_MODAL })
                dispatch({ type: actionTypes.MYFILES_RENAME_FOLDERNAME, payload: res.response })
            } else {
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
            }
        } else {
            dispatch({ type: actionTypes.MYFILES_RENAME_MODAL })
        }
    }
}
export const renameMyfilesFolder = (value) => {
    const data = {
        folderName: value.folderName,
        type: value.type,
        folderId: value.id,
        userId: sessionStorage.userId ? sessionStorage.userId : ''
    };
    return async (dispatch) => {
        const res = await fetchData({ url: '/user/myfiles/rename/folder', method: 'POST', data })
        if (res.status === 1) {
            const id = sessionStorage.myfilesSelectedFolderId ? sessionStorage.myfilesSelectedFolderId : '';
            dispatch({ type: actionTypes.MYFILES_RENAME_MODAL })
            dispatch(getFolderData(id))
        }
        dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
    }
}

export const createMyFilesModal = (id, bool) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.MYFILES_FILE_CREATE_MODAL, payload: id, payloadTwo: bool });
    }
}

export const getMyfileCreateOptions = (searchVal) => {
    return async (dispatch) => {
        const userId = sessionStorage.userId ? sessionStorage.userId : '';
        const res = await fetchData({ url: `/user/get/ref/docs?userId=${userId}&searchVal=${searchVal}`, method: 'GET' });
        res.status === 1 ?
            dispatch({ type: actionTypes.MYFILE_FILE_OPTIONS, payload: res.response })
            : dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
    }
}

export const creteNewMyFile = (obj, history) => {
    return async (dispatch) => {
        const id = obj && obj.selectedFolder ? obj.selectedFolder : sessionStorage.myfilesSelectedFolderId ? sessionStorage.myfilesSelectedFolderId : '',
            userId = sessionStorage.userId ? sessionStorage.userId : '';
        const data = {
            ...obj,
            rootId: id,
            userId: userId
        }
        const res = await fetchData({ url: '/create/new/myfiles/file', method: 'POST', data });
        if (res.status === 1) {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'File created sucessfully', alignment: "centre" } });
            if(res.openType === 'onCommon'){
                dispatch(getFolderData(id))
                dispatch(openMyfile(res.response, history))
                dispatch(createMyFilesModal())
            }else if(res.openType === 'onDoubleClick'){
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'File created sucessfully', alignment: "centre" } });
                dispatch(getFolderData(id))
                dispatch({ type: actionTypes.LOCK_DOC_USER_MODAL_VIEW, payload: {} })
                history.push('/myDocuments');
            }else if(res.openType === 'onEditPreview'){
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'File created sucessfully', alignment: "centre" } });
                dispatch(getFolderData(id))
                dispatch({ type: actionTypes.PREVIEW_MODAL_VIEW, payload: {} })
                dispatch({ type: actionTypes.LOCK_DOC_USER_MODAL_VIEW, payload: {} })
                history.push('/myDocuments');
            }
           
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
};

export const toggleMoveModal = (id, type) => {
    return async (dispatch) => {
        if (id) {
            const userId = sessionStorage.userId ? sessionStorage.userId : '';
            const folderId = type === 'move' ? '' : id;
            const res = await fetchData({ url: `/user/myfile/allFolders?userId=${userId}&folderId=${folderId}`, method: 'GET' });
            if (res.status === 1) {
                dispatch({ type: actionTypes.MYFILE_FOLDER_STRCTURE, payload: res.response, root: res.root })
                if (type !== 'sub') {
                    dispatch({ type: actionTypes.MYFILE_MOVE_MODAL })
                }
            } else {
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
            }
        } else {
            dispatch({ type: actionTypes.MYFILE_MOVE_MODAL })
        }

    }
}

export const setEditorContent = (val) => ({ type: actionTypes.MYFILES_EDITOR_CONTENT, payload: val })

export const toggleSendModal = (url) => ({ type: actionTypes.TOGGLE_MYFILES_SEND_MODAL, payload: url })

export const sendEmail = (val) => {
    return async (dispatch) => {
        if (val && val.email && val.subject && val.message) {
            dispatch({ type: actionTypes.BUTTON_CHANGE_MODAL })
            const data = {
                ...val,
                userId: sessionStorage.userId ? sessionStorage.userId : '',
                fileId: sessionStorage.myfilesId ? sessionStorage.myfilesId : sessionStorage.selectedOptionId ? sessionStorage.selectedOptionId : ''
            }
            const res = await fetchData({ url: '/user/send/myfiles', method: 'POST', data })
            if (res.status === 1) {
                dispatch(toggleSendModal())
            }
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
            dispatch({ type: actionTypes.BUTTON_CHANGE_MODAL })
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'Please fill the required fields', alignment: "centre" } });
        }
    }
};

export const saveMyFiles = (content) => {
    return async (dispatch) => {
        const data = {
            content: content,
            userId: sessionStorage.userId ? sessionStorage.userId : '',
            fileId: sessionStorage.myfilesId ? sessionStorage.myfilesId : ''
        }
        const res = await fetchData({ url: '/user/myfiles/save', method: 'POST', data });
        if (res.status === 1) {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } })
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } })
        }
    }
};

export const toggleViewModal = (from) => {
    return async (dispatch) => {
        if (from === 'menu') {
            const userId = sessionStorage.userId ? sessionStorage.userId : '',
                fileId = sessionStorage.myfilesId ? sessionStorage.myfilesId : sessionStorage.selectedOptionId ? sessionStorage.selectedOptionId : '';
            const res = await fetchData({
                url: `/user/myfiles/view/file?fileId=${fileId}&userId=${userId}`, method: 'GET',
            });
            if (res.status === 1) {
                dispatch({ type: actionTypes.TOGGLE_MYFILES_VIEW_MODAL, payload: res.response.headerContent + res.response.innerContent + res.response.footerContent })
            } else {
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } })
            }
        } else {
            dispatch({ type: actionTypes.TOGGLE_MYFILES_VIEW_MODAL })
        }
    }
}

export const submitMove = (id, type) => {
    return async (dispatch) => {
        const data = {
            userId: sessionStorage.userId ? sessionStorage.userId : '',
            id: id,
            fileId: sessionStorage.selectedOptionId ? sessionStorage.selectedOptionId : '',
            type: type
        }
        const res = await fetchData({ url: '/user/myfiles/move', method: 'POST', data });
        if (res.status === 1) {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } })
            dispatch({ type: actionTypes.MYFILE_MOVE_MODAL })
            dispatch(getFolderData(id))
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } })
        }
    }
};

export const toggleDeleteModal = (type, key) => ({ type: actionTypes.TOGGLE_MYFILE_DELETE_MODAL, payload: type, key: key });

export const DeleteFunc = (type, key) => {
    return async (dispatch) => {
        const res = await fetchData({
            url: '/user/myfiles/delete', method: 'POST', data: {
                id: sessionStorage.selectedOptionId ? sessionStorage.selectedOptionId : '',
                userId: sessionStorage.userId ? sessionStorage.userId : '',
                type: type,
                key: key
            }
        });
        if (res.status === 1) {
            const selected = sessionStorage.myfilesSelectedFolderId ? sessionStorage.myfilesSelectedFolderId : '';
            dispatch(getFolderData(selected))
            dispatch(({ type: actionTypes.TOGGLE_MYFILE_DELETE_MODAL }))
        }
        dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } })
    }
}

export const toggleFolderMoveModal = (id, type) => {
    return async (dispatch) => {
        if (id) {
            const userId = sessionStorage.userId ? sessionStorage.userId : '';
            const folderId = type === 'move' ? '' : id;
            const res = await fetchData({ url: `/user/myfile/allFolders?userId=${userId}&folderId=${folderId}`, method: 'GET' });
            if (res.status === 1) {
                dispatch({ type: actionTypes.MYFILE_FOLDER_STRCTURE, payload: res.response, root: res.root })
                if (type !== 'sub') {
                    dispatch({ type: actionTypes.MYFILE_FOLDER_MOVE })
                }
            } else {
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
            }
        } else {
            dispatch({ type: actionTypes.MYFILE_FOLDER_MOVE })
        }
    }
}

export const submitFolderMove = (selectedFolderId) => {
    return async (dispatch) => {
        if (!!selectedFolderId) {
            const data = {
                userId: sessionStorage.userId ? sessionStorage.userId : '',
                selectedFolderId: selectedFolderId,
                folderId: sessionStorage.selectedOptionId ? sessionStorage.selectedOptionId : '',
            }
            const res = await fetchData({ url: `/user/myfile/folder/move`, method: 'POST', data });
            if (res.status === 1) {
                dispatch(getFolderData(selectedFolderId))
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
            } else {
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
            }
            dispatch({ type: actionTypes.MYFILE_FOLDER_MOVE })
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'Please Select Folder', alignment: "centre" } });
        }
    }
};

export const viewPdf = (id, type) => {
    // downloadWord
    return async (dispatch) => {
        dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: type === 'downloadWord' ? 'Genetating Word' : 'Generatinf PDF', alignment: "bottom" } });
        const data = {
            userId: sessionStorage.userId ? sessionStorage.userId : '',
            fileId: id,
            type: type === 'downloadWord' ? 'word' : 'pdf'
        }
        const res = await fetchData({ url: `/user/myfile/pdf`, method: 'POST', data });
        if (res.status === 1) {
            if (type === 'view' || type === 'downloadWord') {
                window.open(res.response, '_blank');
            } else if (type === 'downloadPdf') {
                let oReq = new XMLHttpRequest();
                let URLToPDF = res.response;
                let url = window.location.origin + new URL(URLToPDF).pathname;
                let filename = res.fileName
                oReq.open("GET", url, true);
                oReq.setRequestHeader('Access-Control-Allow-Origin', '*');
                oReq.responseType = "blob";
                oReq.onload = function () {
                    let file = new Blob([oReq.response], {
                        type: 'application/pdf'
                    });
                    saveAs(file, filename + ".pdf");
                };
                oReq.send();
            }
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: false, text: 'Document Downloaded Successfully', alignment: "centre" } });
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'Something Went wrong', alignment: "centre" } });
        }
    }
}

export const submitFolderCopy = (selectedFolderId) => {
    return async (dispatch) => {
        if (!!selectedFolderId) {
            const data = {
                userId: sessionStorage.userId ? sessionStorage.userId : '',
                selectedFolderId: selectedFolderId,
                folderId: sessionStorage.selectedOptionId ? sessionStorage.selectedOptionId : '',
            }
            const res = await fetchData({ url: `/user/myfile/folder/copy`, method: 'POST', data });
            if (res.status === 1) {
                dispatch(getFolderData(selectedFolderId))
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
            } else {
                dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
            }
            dispatch({ type: actionTypes.MYFILE_FOLDER_MOVE })
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: 'Please Select Folder', alignment: "centre" } });
        }
    }
}

export const dragingId = (id, type) => ({ type: actionTypes.DRAGGING_ID, payload: { id, type } })
export const dropId = (id, type) => ({ type: actionTypes.DROP_ID, payload: { id, type } })
export const confirmationModal = () => ({ type: actionTypes.CONFIRMATION_MODAL })


export const fileDragSubmit = (dropLocationFolderId, draggingFileId) => {
    return async (dispatch) => {
        const data = {
            userId: sessionStorage.userId ? sessionStorage.userId : '',
            id: dropLocationFolderId ? dropLocationFolderId : null,
            fileId: draggingFileId ? draggingFileId : '',
            type: 'move'
        }
        const res = await fetchData({ url: '/user/myfiles/move', method: 'POST', data });
        if (res.status === 1) {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } })
            dispatch({ type: actionTypes.CONFIRMATION_MODAL })
            dispatch(getFolderData(dropLocationFolderId))
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } })
        }
    }
};

export const folderDragSubmit = (dropLocationFolderId, draggingFolderId) => {
    return async (dispatch) => {
        const data = {
            userId: sessionStorage.userId ? sessionStorage.userId : '',
            selectedFolderId: dropLocationFolderId,
            folderId: draggingFolderId ? draggingFolderId : '',
        }
        const res = await fetchData({ url: `/user/myfile/folder/move`, method: 'POST', data });
        if (res.status === 1) {
            dispatch({ type: actionTypes.CONFIRMATION_MODAL })
            dispatch(getFolderData(dropLocationFolderId))
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
};

export const getMyfilesSentList = (skip, limit, activePage) => {
    return async (dispatch) => {
        const params = {
            userId: sessionStorage.userId ? sessionStorage.userId : '', skip, limit
        }
        dispatch({ type: actionTypes.MYFILES_ACTIVE_PAGE, payload: activePage, skip: skip })
        const res = await fetchData({ url: '/user/sent/file/list', method: 'GET', params })
        if (res.status === 1) {
            dispatch({ type: actionTypes.SENT_LIST, payload: res.response, count: res.totalCount })
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
};

export const openUploadFileModal = () => ({ type: actionTypes.UPLOAD_FILES })

export const uploadFile = (obj) => {
    const id = sessionStorage.myfilesSelectedFolderId ? sessionStorage.myfilesSelectedFolderId : '',
        userId = sessionStorage.userId ? sessionStorage.userId : '';
    let formData = new FormData();
    formData.append('file', obj.pdfFile);
    formData.append('fileName', obj.fileName);
    formData.append('parentId', id);
    formData.append('userId', userId);
    return async (dispatch) => {
        const res = await fetchData({ url: '/user/myfiles/upload', method: 'POST', data: formData })
        if (res.status === 1) {
            dispatch(openUploadFileModal())
            dispatch(getFolderData(id))
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        } else {
            dispatch({ type: actionTypes.BOTTOM_T_W_AL, payload: { show_hide: true, text: res.response, alignment: "centre" } });
        }
    }
}

export const togglePdfView = (link) => ({ type: actionTypes.PDF_VIEWER, payload: link })