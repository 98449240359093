import axios from 'axios';
// import CONFIG from '../config';

var tokens = sessionStorage.getItem('token');
var AUTH_TOKEN;
if (tokens) {
   AUTH_TOKEN = tokens;
   AUTH_TOKEN = tokens;
}

// /* Localhost URL CONFIG */
// export const NodeURL = "http://localhost:3007";
// export const RootdomainURL = "localhost:3001";
// export const visitSiteURL = "http://www.localhost:3001";

/* LIVE URL CONFIG */
// export const NodeURL = "https://www.cloudoc.co.uk";
// export const RootdomainURL = "cloudoc.co.uk";
// export const visitSiteURL = "https://www.cloudoc.co.uk";

/* STAGING URL CONFIG */
export const NodeURL = "https://www.caredocument.casperon.co";
export const RootdomainURL = "caredocument.casperon.co";
export const visitSiteURL = "https://www.caredocument.casperon.co";

export const client = axios.create({
   baseURL: NodeURL
});

client.defaults.headers.common['authorization'] = AUTH_TOKEN;

/**
 *
 * @param {import('axios').AxiosRequestConfig} options
 * @returns
 */
const fetchData = async (options) => {
   const onSuccess = response => {
      if (response.data.status === "00") {
         window.location = "/admin"
      }
      return response.data; // This in turn returns data to be shown in the frontend
   };
   const onError = error => {
      if (error.response) {
         // Request was made but server responded with something other than 2xx
      } else {
         // Something else happened while setting up the request triggered the error
      }
      return Promise.reject(error.response || error.message);
   };
   return client(options)
      .then(onSuccess)
      .catch(onError);
}

export default fetchData;

export const displayShortName = (name) => {
   var displayShortName = (name) ? name.charAt(0) + name.charAt(1) : null;
   return displayShortName;
}

export const displayName = (name) => {
   var displayName = (name) ? name : null;
   return displayName;
}

export const displayEmail = (email) => {
   var displayEmail = (email) ? email : null;
   return displayEmail;
}

export class UploadAdapter {
   constructor(loader) {
      this.loader = loader
   }
   async upload() {
      return this.loader.file.then((file) => {
         const data = new FormData()
         data.append("templateImage", file);
         const genericError = `Couldn't upload file: ${file.name}.`
         var AUTH_TOKEN = sessionStorage.getItem('token');
         if (AUTH_TOKEN) {
            axios.defaults.headers['authorization'] = AUTH_TOKEN;
         }
         return axios({
            data,
            method: "POST",
            url: NodeURL + "/admin/template/image/upload",
            headers: {
               "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
               this.loader.uploadTotal = progressEvent.total
               this.loader.uploaded = progressEvent.loaded
               const uploadPercentage = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
               )
            },
         }).then(({ data }) => ({
            default: NodeURL + '/' + data.message.templateImage
         })).catch(({ error }) => Promise.reject(error?.message ?? genericError))
      })
   }

   abort() {
      return Promise.reject()
   }
}

// CKEditor FileRepository
export function uploadAdapterPlugin(editor) {
   editor.plugins.get("FileRepository").createUploadAdapter = (loader) =>
      new UploadAdapter(loader)
}

export let validateEmail = (email) => {
   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   return re.test(String(email).toLowerCase());
};

export const checkValue = (e) => {
   if (String(e).length > 0 && String(e) !== String(undefined) && String(e) !== String(null)) {
      return true;
   } else {
      return false;
   }
}


export const NotUsedVariables = () => {
   return [
      { 'key': "document.title", "value": '' },
      { 'key': "document.policynumber", "value": '' },
      { 'key': "document.reviewdate", "value": '' },
      { 'key': "first.name", "value": '' },
      { 'key': "last.name", "value": '' },
      { 'key': "contact.email", "value": '' },
      { 'key': "contact.phonecode", "value": '' },
      { 'key': "contact.phonenumber", "value": '' },
      { 'key': "contact.fax", "value": '' },
      { 'key': "signature.image", "value": '' },
      { 'key': "organisation.type", "value": '' },
      { 'key': "company.name", "value": '' },
      { 'key': "company.registerno", "value": '' },
      { 'key': "company.logo", "value": '' },
      { 'key': "company.director", "value": '' },
      { 'key': "company.address", "value": '' },
      { 'key': "company.email", "value": '' },
      { 'key': "company.website", "value": '' },
      { 'key': "register.line1", "value": '' },
      { 'key': "register.line2", "value": '' },
      { 'key': "register.city", "value": '' },
      { 'key': "register.state", "value": '' },
      { 'key': "register.country", "value": '' },
      { 'key': "register.postalcode", "value": '' },
      { 'key': "trading.name", "value": '' },
      { 'key': "trading.address", "value": '' },
      { 'key': "trading.line1", "value": '' },
      { 'key': "trading.line2", "value": '' },
      { 'key': "trading.city", "value": '' },
      { 'key': "trading.state", "value": '' },
      { 'key': "trading.country", "value": '' },
      { 'key': "trading.postalcode", "value": '' },
      { 'key': "trading.area", "value": '' },
      { 'key': "director.lastname", "value": '' },
      { 'key': "director.email", "value": '' },
      { 'key': "director.phone", "value": '' },
      { 'key': "provider.name", "value": '' },
      { 'key': "provider.cqc.no", "value": '' },
      { 'key': "nominated.firstname", "value": '' },
      { 'key': "nominated.lastname", "value": '' },
      { 'key': "nominated.email", "value": '' },
      { 'key': "nominated.phone", "value": '' },
      { 'key': "register.manager.firstname", "value": '' },
      { 'key': "register.manager.lastname", "value": '' },
      { 'key': "register.manager.email", "value": '' },
      { 'key': "register.manager.phone", "value": '' },
      { 'key': "register.manager.signature", "value": '' },
      { 'key': "protection.officer.firstname", "value": '' },
      { 'key': "protection.officer.lastname", "value": '' },
      { 'key': "protection.officer.email", "value": '' },
      { 'key': "protection.officer.phone", "value": '' },
      { 'key': "location.name", "value": '' },
      { 'key': "location.address", "value": '' },
      { 'key': "location.address.line1", "value": '' },
      { 'key': "location.address.line2", "value": '' },
      { 'key': "location.address.city", "value": '' },
      { 'key': "location.address.state", "value": '' },
      { 'key': "location.address.country", "value": '' },
      { 'key': "location.address.postalcode", "value": '' },
      { 'key': "location.address.email", "value": '' },
      { 'key': "location.address.phone", "value": '' },
      { 'key': "regulated.activity", "value": '' },
      { 'key': "local.authority.name", "value": '' },
      { 'key': "safeguarding.officer", "value": '' },
      { 'key': "safeguarding.link", "value": '' },
      { 'key': "safeguarding.phone", "value": '' },
      { 'key': "charge.per.hour", "value": '' },
      { 'key': "service.band", "value": '' },
      { 'key': "mobile.number", "value": '' },
      { 'key': "landlord.firstname", "value": '' },
      { 'key': "landlord.lastname", "value": '' },
      { 'key': "landlord.address.line1", "value": '' },
      { 'key': "landlord.address.line2", "value": '' },
      { 'key': "landlord.address.city", "value": '' },
      { 'key': "landlord.address.state", "value": '' },
      { 'key': "landlord.address.country", "value": '' },
      { 'key': "landlord.address.postalcode", "value": '' },
      { 'key': "landlord.email", "value": '' },
      { 'key': "landlord.phone", "value": '' },
      { 'key': "department", "value": '' },
      { 'key': "document.date", "value": '' },
      { 'key': "document.version", "value": '' },
      { 'key': "name_of_home", "value": '' },
      { 'key': "infection.control", "value": '' },
      { 'key': "designated.safeguarding.lead.name", "value": '' },
      { 'key': "medication.management", "value": '' },
      { 'key': "recruitment.lead", "value": '' },
      { 'key': "complaints.manager", "value": '' },
      { 'key': "nominated.individual", "value": '' },
      { 'key': "local.authority.designated.officer", "value": '' },
      { 'key': "local.children.social.name", "value": '' },
      { 'key': "local.children.social.phone", "value": '' },
      { 'key': "local.children.social.email", "value": '' },
      { 'key': "local.children.social.website", "value": '' },
      { 'key': "local.children.social.address", "value": '' },
      { 'key': "safeguarding.children.partnership.name", "value": '' },
      { 'key': "safeguarding.children.partnership.phone", "value": '' },
      { 'key': "safeguarding.children.partnership.email", "value": '' },
      { 'key': "safeguarding.children.partnership.website", "value": '' },
      { 'key': "safeguarding.children.partnership.address", "value": '' },
      { 'key': "safeguarding.adult.partnership.name", "value": '' },
      { 'key': "safeguarding.adult.partnership.phone", "value": '' },
      { 'key': "safeguarding.adult.partnership.email", "value": '' },
      { 'key': "safeguarding.adult.partnership.website", "value": '' },
      { 'key': "safeguarding.adult.partnership.address", "value": '' },



   ]
}