import { lazy } from 'react';



const HomeComponent = lazy(() => import('../modules/Home')),
    PagesComponent = lazy(() => import('../modules/Pages')),
    SubscriptionListComponent = lazy(() => import('../modules/Subscriptions/SubscriptionList')),
    SubscriptionDetailComponent = lazy(() => import('../modules/Subscriptions/SubscriptionDetail')),
    PackageListComponent = lazy(() => import('../modules/Home/Search')),
    PackageDetailComponent = lazy(() => import('../modules/ProductDetail')),
    DocumentSearchComponent = lazy(() => import('../modules/Home/DocumentSearch')),
    CartComponent = lazy(() => import('../modules/Cart')),
    CheckoutComponent = lazy(() => import('../modules/Checkout')),
    OrderComponent = lazy(() => import('../modules/Orders')),
    OrderDetailsComponent = lazy(() => import('../modules/Orders/Details')),
    DashboardComponent = lazy(() => import('../modules/Dashboard')),
    RecentUpdatesComponent = lazy(() => import('../modules/Documents/RecentUpdates')),
    DocumentCompareComponent = lazy(() => import('../modules/Documents/DocumentCompare')),
    DocumentListComponent = lazy(() => import('../modules/Documents/ListDocument')),
    DocumentEditorComponent = lazy(() => import('../modules/Documents/DocumentEditor')),
    UserSubscriptionListComponent = lazy(() => import('../modules/Subscriptions/PlanSubscription')),
    // UserSubscriptionListComponent = lazy(() => import('../modules/Subscriptions/NewSubscription')),
    SubscriptionPayComponent = lazy(() => import('../modules/Subscriptions/Payment')),
    UsersListComponent = lazy(() => import('../modules/Users')),
    EmployeeGroups = lazy(() => import('../modules/EmployeeGroups')),
    AddGroupComponent = lazy(() => import('../modules/EmployeeGroups/AddGroup')),
    EditGroupComponent = lazy(() => import('../modules/EmployeeGroups/EditGroup')),
    ViewGroupComponent = lazy(() => import('../modules/EmployeeGroups/ViewGroup')),
    EmployeePolicies = lazy(() => import('../modules/EmployeePolicies')),
    AddPolicy = lazy(() => import('../modules/EmployeePolicies/Add')),
    EditPolicy = lazy(() => import('../modules/EmployeePolicies/Edit')),
    UserAddComponent = lazy(() => import('../modules/Users/Add')),
    UserEditComponent = lazy(() => import('../modules/Users/Edit')),
    ViewUserComponent = lazy(() => import('../modules/Users/View')),
    USERSACTIVTYCOMPONENT = lazy(() => import('../modules/Users/Activity')),
    UserDeleteComponent = lazy(() => import('../modules/Users/Deleted')),
    // EmployeePolicyDocuments = lazy(() => import('../modules/EmployeePolicies/UserPolicyDocuments')),
    IndexComponent = lazy(() => import('../modules/Documents/IndexPage')),
    SettingsComponent = lazy(() => import('../modules/Users/settings/index')),
    NoMatchComponent = lazy(() => import('../modules/NoMatch')),
    PDF_EXPIRE_DOCUMENT = lazy(() => import('../modules/pdf_viwer')),
    SAVED_DOCUMENTS = lazy(() => import('../modules/Documents/saved-documents')),
    CREATE_NEW_SAVED_DOCS = lazy(() => import('../modules/Documents/create_editor')),
    OrderHistory = lazy(() => import('../modules/Orders/OrderHistory')),
    New_Profile = lazy(() => import('../modules/MyProfile')),
    ContactUs = lazy(() => import('../modules/Contact/ContactUs')),
    Features = lazy(() => import('../modules/Features/Features')),
    Knowledge = lazy(() => import('../modules/Knowledge Base/index')),
    DocManagementPayment = lazy(() => import('../modules/Subscriptions/DocManagementPayment')),
    FreeDocumentDownload = lazy(() => import('../modules/FreePolicies')),
    ViewInvoiceDetails = lazy(() => import('../modules/Subscriptions/Invoice/ViewInvoice')),
    NeedReviewList = lazy(() => import('../modules/Documents/NeedReviewList')),
    DocumentUpdate = lazy(() => import('../modules/Documents/DocumentUpdate')),
    UptoDateDocuments = lazy(() => import('../modules/Documents/UptoDateDocuments')),
    EmployeesListComponent = lazy(() => import('../modules/Employees')),
    AddEmployeeComponent = lazy(() => import('../modules/Employees/Add')),
    DeletedEmployeesComponent = lazy(() => import('../modules/Employees/DeletedList')),
    EditEmployeeComponent = lazy(() => import('../modules/Employees/Edit')),
    ViewEmployeeComponent = lazy(() => import('../modules/Employees/View')),
    ViewDocument = lazy(() => import('../modules/Subscriptions/ViewDocument')),
    // HistoryList = lazy(() => import('../modules/History')),
    ReferenceDocView = lazy(() => import('../modules/Documents/DocumentEditor/ReferenceDocumentView')),
    ForgotPassword = lazy(() => import('../modules/ForgotPassword/ForgotPassword')),
    Otpverification = lazy(() => import('../modules/ForgotPassword/Otpverification')),
    ResetPassword = lazy(() => import('../modules/ForgotPassword/resetpassword')),
    LoginComponent = lazy(() => import('../modules/Login')),
    MyfilesComponent = lazy(() => import('../modules/Myfiles/index')),
    MyfileEditorComponent = lazy(() => import('../modules/Myfiles/MyfileEditor')),
    MyfileDetailsComponent = lazy(() => import('../modules/Myfiles/MyfilesDetails')),
    MyDocumentsComponent = lazy(() => import('../modules/MyDocuments/index')),
    MyDocumentsEditorComponent = lazy(() => import('../modules/MyDocuments/MyfileEditor')),
    MyDocumentsDetailsComponent = lazy(() => import('../modules/MyDocuments/MyfilesDetails')),
    PublishedPolicies = lazy(() => import('../modules/PublishedPolicies')),
    PublicPolicies = lazy(() => import('../modules/PublicPolicies')),
    ViewPolicy = lazy(() => import('../modules/PublicPolicies/ViewPolicy')),
    AddMultiPolicy = lazy(() => import('../modules/EmployeePolicies/Add/MultipleDocument')),
    DocumentBinComponent = lazy(() => import('../modules/Documents/Bin')),
    KnowledgeBase = lazy(() => import('../modules/Knowledge Base/index')),
    VideoPage = lazy(() => import('../modules/Knowledge Base/index-2')),


    //doc sign
    DocSign = lazy(() => import('../modules/DocumentSign/sign')),
    DocSignSuccess = lazy(() => import('../modules/DocumentSign/SuccessPage')),
    //Dyanamic FAQ
    FAQComponent = lazy(() => import('../modules/FAQ/faq')),
    //course
    CourseComponent = lazy(() => import('../modules/Course/courseList')),

    //Documentation
    Documentation = lazy(() => import('../modules/Documentation/view')),
    DocumentLibrary = lazy(() => import('../modules/Subscriptions/DocumentLibrary/DocLib')),
    ReadyTousePolicy = lazy(() => import('../modules/Subscriptions/SubscriptionDetail/KnowMore/readyTousePolicy')),
    UptoDateDocumentsPricing = lazy(() => import('../modules/Subscriptions/SubscriptionDetail/KnowMore/uptoDateDocuments')),
    FlexiblePricing = lazy(() => import('../modules/Subscriptions/SubscriptionDetail/KnowMore/flexiblePricing'));


//  HomeComponent = lazy(() => import('../modules/Home/new-home')), 
// SubscriptionDetailComponent = lazy(() => import('../modules/Subscriptions/SubscriptionDetail/newsubscriptiondetails_copy')),
// ViewSubscriptions = lazy(() => import('../modules/Subscriptions/NewSubscription/ViewSubscriptions'));
// const HomeComponent = lazy(() => import('../modules/Home'));
// DocumentPreview = lazy(() => import('../modules/Pages/DocumentPreview')),
// resetpassword = lazy(() => import('../modules/ForgotPassword/resetpassword')),
// const LoginComponent = lazy(() => import('../modules/Login'));
// const RegisterComponent = lazy(() => import('../modules/Signup'));
// const RegisterComponent = lazy(() => import('../modules/Signup/new-sign-up'));
// const ProfileComponent = lazy(() => import('../modules/MyProfile'));
// const SubscriptionListComponent = lazy(() => import('../modules/Subscriptions/SubscriptionList'));
// const SubscriptionListComponent = lazy(() => import('../modules/Subscriptions/SubscriptionList/new-subscription-list'));
// const SubscriptionDetailComponent = lazy(() => import('../modules/Subscriptions/SubscriptionDetail/newsubscriptiondetails'));
// const CartComponent = lazy(() => import('../modules/Cart'));
// const CheckoutComponent = lazy(() => import('../modules/Checkout'));
// const CheckoutComponent = lazy(() => import('../modules/Checkout/NewCheckout'));
// const UserSubscriptionListComponent = lazy(() => import('../modules/Subscriptions'));

const routes = [
    { 'path': '/', 'component': HomeComponent, 'exact': true, 'private': false },
    { 'path': '/login', 'component': LoginComponent, 'exact': true, 'private': false },
    { 'path': '/forgotpassword', 'component': ForgotPassword, 'exact': true, 'private': false },
    { 'path': '/verifyotp', 'component': Otpverification, 'exact': true, 'private': false },
    { 'path': '/resetpassword', 'component': ResetPassword, 'exact': true, 'private': false },
    { 'path': '/company/knowledge', 'component': KnowledgeBase, 'exact': true, 'private': false },
    { 'path': '/company/knowledge/video', 'component': VideoPage, 'exact': true, 'private': false },
    // { 'path': '/employee/login', 'component': EmployeeLogin, 'exact': true, 'private': false },
    // { 'path': '/employee/register', 'component': EmployeeSignUp, 'exact': true, 'private': false },
    { 'path': '/pages/:slug', 'component': PagesComponent, 'exact': true, 'private': false },
    { 'path': '/policies-procedures', 'component': SubscriptionListComponent, 'exact': true, 'private': false },
    { 'path': '/subscription-detail/:slug', 'component': SubscriptionDetailComponent, 'exact': true, 'private': false },
    { 'path': '/package-search', 'component': PackageListComponent, 'exact': true, 'private': false },
    { 'path': '/package-detail/:slug', component: PackageDetailComponent, 'exact': true, 'private': false },
    { 'path': '/document-search', 'component': DocumentSearchComponent, 'exact': true, 'private': false },
    { 'path': '/cart', 'component': CartComponent, 'exact': true, 'private': false },
    { 'path': '/policy/documents', 'component': PublicPolicies, 'exact': true, 'private': false },
    { 'path': '/policy/document/view', 'component': ViewPolicy, 'exact': true, 'private': false },
    { 'path': '/checkout', 'component': CheckoutComponent, 'exact': true, 'private': true },
    { 'path': '/orders', 'component': OrderComponent, 'exact': true, 'private': true },
    { 'path': '/order-detail/:id', 'component': OrderDetailsComponent, 'exact': true, 'private': true },
    { 'path': '/dashboard', 'component': DashboardComponent, 'exact': true, 'private': true },
    { 'path': '/documents/recent-updates', 'component': RecentUpdatesComponent, 'exact': true, 'private': true },
    { 'path': '/documents/compare/', 'component': DocumentCompareComponent, 'exact': true, 'private': true },
    { 'path': '/saved-documents', 'component': SAVED_DOCUMENTS, 'exact': true, 'private': true },
    { 'path': '/create-new-documents', 'component': CREATE_NEW_SAVED_DOCS, 'exact': true, 'private': true },
    { 'path': '/documents', 'component': DocumentListComponent, 'exact': true, 'private': true },
    { 'path': '/documents/editor/', 'component': DocumentEditorComponent, 'exact': true, 'private': true },
    { 'path': '/settings', 'component': SettingsComponent, 'exact': true, 'private': true },
    { 'path': '/subscriptions', 'component': UserSubscriptionListComponent, 'exact': true, 'private': true },
    { 'path': '/invoice', 'component': ViewInvoiceDetails, 'exact': true, 'private': true },
    { 'path': '/subscription/pay', 'component': SubscriptionPayComponent, 'exact': true, 'private': true },
    { 'path': '/users', 'component': UsersListComponent, 'exact': true, 'private': true },
    { 'path': '/users/add', 'component': UserAddComponent, 'exact': true, 'private': true },
    { 'path': '/users/edit', 'component': UserEditComponent, 'exact': true, 'private': true },
    { 'path': '/users/view', 'component': ViewUserComponent, 'exact': true, 'private': true },
    { 'path': '/users/activity', 'component': USERSACTIVTYCOMPONENT, 'exact': true, 'private': true },
    { 'path': '/users/deleted-list', 'component': UserDeleteComponent, 'exact': true, 'private': true },
    // { 'path': '/policy-documents', 'component': EmployeePolicyDocuments, 'exact': true, 'private': true },
    { 'path': '/groups', 'component': EmployeeGroups, 'exact': true, 'private': true },
    { 'path': '/group/add', 'component': AddGroupComponent, 'exact': true, 'private': true },
    { 'path': '/group/edit', 'component': EditGroupComponent, 'exact': true, 'private': true },
    { 'path': '/group/view', 'component': ViewGroupComponent, 'exact': true, 'private': true },
    { 'path': '/policies', 'component': EmployeePolicies, 'exact': true, 'private': true },
    { 'path': '/policies/create', 'component': AddPolicy, 'exact': true, 'private': true },
    { 'path': '/policies/multi/create', 'component': AddMultiPolicy, 'exact': true, 'private': true },
    { 'path': '/policies/edit', 'component': EditPolicy, 'exact': true, 'private': true },
    { 'path': '/index', 'component': IndexComponent, 'exact': true, 'private': true },
    { 'path': '/view/:mainpath/:subpath/:name', 'component': PDF_EXPIRE_DOCUMENT, 'exact': true, 'private': false },
    { 'path': '/profile', 'component': New_Profile, 'exact': true, 'private': true },
    { 'path': '/order/history', 'component': OrderHistory, 'exact': true, 'private': true },
    { 'path': '/contact-us', 'component': ContactUs, 'exact': true, 'private': false },
    { 'path': '/features', 'component': Features, 'exact': true, 'private': false },
    { 'path': '/knowledge', 'component': Knowledge, 'exact': true, 'private': false },
    { 'path': '/document-updates', 'component': DocumentUpdate, 'exact': true, 'private': false },
    { 'path': '/document/management/renewal', 'component': DocManagementPayment, 'exact': true, 'private': true },
    { 'path': '/download/sample/documents', 'component': FreeDocumentDownload, 'exact': true, 'private': false },
    { 'path': '/publishedpolicies', 'component': PublishedPolicies, 'exact': true, 'private': true },
    { 'path': '/documents/need-review', 'component': NeedReviewList, 'exact': true, 'private': true },
    { 'path': '/documents/uptodate', 'component': UptoDateDocuments, 'exact': true, 'private': true },
    // { 'path': '/history', 'component': HistoryList, 'exact': true, 'private': true },
    { 'path': '/employees', 'component': EmployeesListComponent, 'exact': true, 'private': true },
    { 'path': '/employee/add', 'component': AddEmployeeComponent, 'exact': true, 'private': true },
    { 'path': '/employee/edit', 'component': EditEmployeeComponent, 'exact': true, 'private': true },
    { 'path': '/employee/view', 'component': ViewEmployeeComponent, 'exact': true, 'private': true },
    { 'path': '/deleted/employees', 'component': DeletedEmployeesComponent, 'exact': true, 'private': true },
    { 'path': '/view/Document/:slug', 'component': ViewDocument, 'exact': true, 'private': false },
    { 'path': '/reference/:id', 'component': ReferenceDocView, 'exact': true, 'private': true },
    { 'path': '/myfiles', 'component': MyfilesComponent, 'exact': true, 'private': true },
    { 'path': '/myfiles/editor', 'component': MyfileEditorComponent, 'exact': true, 'private': true },
    { 'path': '/myfiles/details', 'component': MyfileDetailsComponent, 'exact': true, 'private': true },
    { 'path': '/myDocuments', 'component': MyDocumentsComponent, 'exact': true, 'private': true },
    { 'path': '/myDocuments/editor', 'component': MyDocumentsEditorComponent, 'exact': true, 'private': true },
    { 'path': '/myDocuments/details', 'component': MyDocumentsDetailsComponent, 'exact': true, 'private': true },
    { 'path': '/deleted-documents', 'component': DocumentBinComponent, 'exact': true, 'private': true },


    //doc sign
    { 'path': '/document/sign', 'component': DocSign, 'exact': true, 'private': false },
    { 'path': '/document/sign/success', 'component': DocSignSuccess, 'exact': true, 'private': false },
    //FAQ
    { 'path': '/faq', 'component': FAQComponent, 'exact': true, 'private': false },
    //Documentation
    { 'path': '/systemdocumentation', 'component': Documentation, 'exact': true, 'private': false },
    { 'path': '/documentlibrary', 'component': DocumentLibrary, 'exact': true, 'private': false },

    //price Simplifies Compliance
    { 'path': '/readytousepolicies/:id', 'component': ReadyTousePolicy, 'exact': true, 'private': false },
    { 'path': '/uptodatedocuments/:id', 'component': UptoDateDocumentsPricing, 'exact': true, 'private': false },
    { 'path': '/flexiblepricing/:id', 'component': FlexiblePricing, 'exact': true, 'private': false },

    //Couse
    { 'path': '/course', 'component': CourseComponent, 'exact': true, 'private': false },

    { 'path': '*', 'component': NoMatchComponent, 'exact': true, 'private': false },
    



    // { 'path': '/user/subscription', 'component': ViewSubscriptions, 'exact': true, 'private': true },
    // { 'path': '/signup', 'component': RegisterComponent, 'exact': true, 'private': false },
    // { 'path': '/profile', 'component': ProfileComponent, 'exact': true, 'private': true },
    // { 'path': '/subscription-detail/:slug', 'component': SubscriptionDetailComponent, 'exact': true, 'private': false },
    // { 'path': '/cart', 'component': CartComponent, 'exact': true, 'private': true },
    // { 'path': '/subscriptions', 'component': UserSubscriptionListComponent, 'exact': true, 'private': true },
    // { 'path': '/faq', 'component': FAQComponent, 'exact': true, 'private': false },
    // { 'path': '/resetpassword/:id/:resetcode', 'component': resetpassword, 'exact': true, 'private': false },
    // { 'path': '/document/preview', 'component': DocumentPreview, 'exact': true, 'private': false },

];

export default routes;